<template>
    <div v-if="stateVendors.loading.vendors" class="w-full flex justify-content-center align-items-center"
        style="height: 273px">
        <ProgressSpinner />
    </div>
    <div v-else class="p-fluid formgrid grid form">

        <div class="field col-12">
            <label>Fornecedor</label>
            <Dropdown class="mt-1" v-model="state.vendor" :options="stateVendors.vendors" optionLabel="name" />
        </div>

        <div class="field col-12">

            <label>Valor</label>
            <InputNumber v-model="state.value" mode="currency" currency="BRL" locale="pt-BR" />

        </div>

        <div class="field col-12">
            <label>Tipo da Chave Pix</label>
            <Dropdown class="mt-1" v-model="state.pixKeyType" optionLabel="name"
                :options="state.vendorsPixKeys[state.vendor.type]" />
        </div>

        <div class="field col-12">
            <label>Chave Pix: ({{ state.pixKeyType.name }})</label>
            <InputText type="text" v-model="state.pixKey" />
        </div>

        <div class="field col-12">
            <label>Nome do recebedor:</label>
            <InputText type="text" v-model="state.reciever_name" />
        </div>

        <div class="field col-12">
            <label>Documento do recebedor: (CPF/CNPJ)</label>
            <InputText type="text" v-model="state.cpf_cnpj" />
        </div>

        <div class="field col-12">
            <Button :loading="state.loading.tryingToMakeRemoval" label="Realizar Retirada" icon="pi pi-check"
                iconPos="right" @click="tryToMakeRemoval" />
        </div>

    </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import services from '../../services'
import { reactive, watch } from 'vue'
import { useStore } from 'vuex'
import useNotificationToast from '../../composables/useNotificationToast'
import useVendors from '../../composables/useVendors'
import ProgressSpinner from 'primevue/progressspinner'

export default {

    components: { InputText, InputNumber, Dropdown, Button, ProgressSpinner },

    setup() {

        const generateOptions = (names, values) => {
            const options = []

            for (let i = 0; i < names.length; i++) {
                options.push({ name: names[i], value: values[i] })
            }

            return options
        }

        const state = reactive({
            vendor: null,
            pixKeyType: 'CNPJ',
            pixKey: null,
            value: null,
            reciever_name: null,
            cpf_cnpj: null,
            loading: {
                tryingToMakeRemoval: false,
                vendors: false
            },
            optionsNames: ['CNPJ', 'CPF', 'CELULAR', 'EMAIL', 'CHAVE ALEATÓRIA'],
            optionsValues: ['CNPJ', 'CPF', 'PHONE', 'EMAIL'],
            vendorsPixKeys: null
        })

        state.vendorsPixKeys = {
            SHIPAY: generateOptions(state.optionsNames, [...state.optionsValues, 'RANDOM_KEY']),
            PRIMEPAG: generateOptions(state.optionsNames, [...state.optionsValues, 'TOKEN']),
            PAYBROKERS: generateOptions(state.optionsNames, [...state.optionsValues, 'EVP'])
        }

        const store = useStore()
        const { success, error } = useNotificationToast()
        const { stateVendors, loadVendors } = useVendors()

        loadVendors()

        watch(() => stateVendors.vendors, (newValue) => {

            state.vendor = newValue[0]

        })

        async function tryToMakeRemoval() {

            state.loading.tryingToMakeRemoval = true

            const token = store.getters.getToken
            const user = store.getters.getUser

            if (
                !state.vendor ||
                !state.pixKey ||
                !state.value ||
                !state.reciever_name ||
                !state.cpf_cnpj) {

                state.loading.tryingToMakeRemoval = false

                return error('Verifique todos os campos!')

            }

            await services.transactions.createRemoval({
                vendor: state.vendor,
                pixKeyType: state.pixKeyType.value,
                pixKey: state.pixKey,
                value: state.value,
                player: {
                    cpf: state.cpf_cnpj,
                    code: user.id.toString(),
                    name: state.reciever_name,
                    email: user.login
                },
                token
            }).then(() => {

                success('Retirada realizada com sucesso!')
                state.pixKey = null
                state.value = null
                state.reciever_name = null
                state.cpf_cnpj = null

                state.loading.tryingToMakeRemoval = false
            })
                .catch((e) => {
                    const message = e.response.data.message
                    const toastMessage = message.charAt(0).toUpperCase() + message.slice(1)
                    error(toastMessage)
                })
                .finally(() => { state.loading.tryingToMakeRemoval = false })


            state.loading.tryingToMakeRemoval = false
        }


        return {
            state,
            stateVendors,
            Symbol,
            tryToMakeRemoval
        }
    }
}

</script>

<style>
.form {
    max-width: 700px;
    margin: 0 auto;
}
</style>